import { Container, Typography } from "@mui/material"
function Home() {
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h2" mb={2}>
        Hi To ELM
      </Typography>
      <a href="https://apps.apple.com/us/app/baby-coloring-book-for-kids-2/id1129192831">
        <img
          src="https://is5-ssl.mzstatic.com/image/thumb/Purple126/v4/51/30/4e/51304ed5-754e-8672-b812-8467cf658828/AppIcon-1x_U007emarketing-0-10-85-220.png/540x540bb.jpg"
          alt="Baby coloring book for kids 2+"
        />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.bimiboo.coloring">
        <img
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
    </Container>
  )
}

export default Home
