import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material"

function PrivacyPage() {
  const policies = [
    {
      question: "Information We Collect",
      answer:
        "We collect only the email address during the account creation process. This information is used for account management purposes and is deleted when the user decides to delete their account.",
    },
    {
      question: "Plant Care Reminders",
      answer:
        "Elm provides plant care reminders based on scanned QR codes. The app sends periodic notifications to remind users to water and feed their plants. Users have the option to set, turn off, or modify these reminders.",
    },
    {
      question: "User Rights",
      answer:
        "Users have the right to delete their account, which results in the deletion of associated email information. If users have any concerns about their privacy, they can contact us using the provided contact details.",
    },
    {
      question: "Geographical Limitation",
      answer:
        "Elm is designed for users in the United Kingdom. If you are located outside the UK, please be aware that your data may be transferred to and processed in the UK",
    },
    {
      question: "Contact",
      answer:
        "If you have any questions, concerns, or feedback regarding your privacy or this Privacy Policy, please contact us at privacy@elmplants.co.uk.",
    },
    {
      question: "Changes to this Policy",
      answer:
        "We may update our Privacy Policy from time to time. Any changes will be posted here, and it's your responsibility to review this policy occasionally.",
    },
  ]

  return (
    <>
      <Container style={{ marginBottom: 50 }}>
        <Typography variant="body1" mb={1}>
          This Privacy Policy explains how we collect, use and safeguard your
          information. By using the Elm Houseplants mobile apps and websites,
          you agree to the terms outlined in this Privacy Policy.
        </Typography>
        <Typography variant="body2" mb={2}>
          Last Updated: 9/01/2024
        </Typography>

        {policies.map((policies, index) => {
          return (
            <Box key={index}>
              <Accordion
                square={true}
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  bgcolor: "primary.main",
                  mb: 0.4,
                }}
              >
                <AccordionSummary
                  id="panel1-header"
                  aria-controls="panel1-content"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>{policies.question}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: "secondary.main" }}>
                  <Typography>{policies.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          )
        })}
      </Container>
    </>
  )
}

export default PrivacyPage
