import { createTheme, colors, ThemeProvider } from "@mui/material"
import Home from "./pages/Home"
import PrivacyPage from "./pages/Privacy-policy"
import TermsPage from "./pages/Terms-and-conditions"
import RootLayout from "./Layouts/RootLayout"
import FaqPage from "./pages/Faq-page"
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="privacy" element={<PrivacyPage />} />
      <Route path="terms" element={<TermsPage />} />
      <Route path="faq" element={<FaqPage />} />
    </Route>,
  ),
)

const theme = createTheme({
  palette: {
    primary: {
      main: "#DAF2EA",
      dark: colors.teal[900],
      light: "#E6DAF2",
    },
    secondary: {
      main: "#ffffff",
      light: "#9887A8",
    },
  },
  typography: {
    body2: {
      fontWeight: "bold",
    },
  },
})
function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
