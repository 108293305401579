import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material"

function TermsPage() {
  const terms = [
    {
      question: "Acceptance of Terms",
      answer:
        " By accessing or using Elm, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use the app.",
    },
    {
      question: "Use of the App",
      answer:
        "Elm is intended for personal, non-commercial use. You may not modify, reproduce, or distribute any content from the app without our explicit permission.",
    },
    {
      question: "User Account",
      answer:
        "To use certain features of Elm, you may need to create a user account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.",
    },
    {
      question: "Plant Care Reminders",
      answer:
        "Elm provides plant care reminders based on scanned QR codes. While we strive to provide accurate information, it is the user's responsibility to verify and ensure the proper care of their plants.",
    },
    {
      question: "User Responsibilities",
      answer:
        "Users are responsible for the accuracy of the information they provide, including email addresses. You agree not to use Elm for any unlawful or unauthorized purpose.",
    },
    {
      question: "Geographical Limitation",
      answer:
        "Elm is designed for users in the United Kingdom. Usage outside the UK may be subject to additional terms and conditions.",
    },
    {
      question: "Modifications to the App",
      answer:
        "We reserve the right to modify or discontinue Elm at any time. We are not liable for any modification, suspension, or discontinuation of the app.",
    },
    {
      question: "Limitation of Liability",
      answer:
        "We are not liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of Elm.",
    },
    {
      question: "Changes to Terms",
      answer:
        "We may update these terms and conditions from time to time. Any changes will be effective immediately upon posting. Your continued use of Elm after changes it means your acceptance of the updated terms.",
    },
    {
      question: "Contact Us",
      answer:
        "If you have any questions or concerns regarding these terms and conditions, please contact us at privacy@elmplants.co.uk.",
    },
  ]

  return (
    <>
      <Container style={{ marginBottom: 50 }}>
        <Typography variant="body1" mb={1}>
          Please read these terms and conditions carefully before using the Elm
          plant care app.
        </Typography>
        <Typography variant="body2" mb={2}>
          Last Updated: 9/01/2024
        </Typography>

        {terms.map((terms, index) => {
          return (
            <Box key={index}>
              <Accordion
                square={true}
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  bgcolor: "primary.main",
                  mb: 0.4,
                }}
              >
                <AccordionSummary
                  id="panel1-header"
                  aria-controls="panel1-content"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>{terms.question}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: "secondary.main" }}>
                  <Typography>{terms.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          )
        })}
      </Container>
    </>
  )
}

export default TermsPage
